import { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export const TextEditor = ({
  label,
  placeholder,
  className,
  value,
  onChange,
}) => {
  const [editorContent, setEditorContent] = useState('');

  const handleChange = (content) => {
    if (onChange) {
      onChange(content); // Update value via form handler
    } else {
      setEditorContent(content); // Update internal state
    }
  };

  return (
    <div className={className} >
      {label && (
        <label className="mb-2 block font-medium text-black dark:text-white">
          {label}
        </label>
      )}
      <ReactQuill
        value={value || editorContent} // Controlled or internal state
        onChange={handleChange}
        placeholder={placeholder}
        className={className}
        modules={{
          toolbar: [
            [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['bold', 'italic', 'underline'],
            ['link', 'image'],
            [{ align: [] }],
          ],
        }}
        formats={[
          'header',
          'font',
          'list',
          'bullet',
          'bold',
          'italic',
          'underline',
          'link',
          'image',
          'align',
        ]}
      />
    </div>
  );
};
