import { ErrorMessage } from "components/common/error-msg";
import React from "react";
import Select from "react-select";

const SelectField = ({
  placeholder,
  selectorLabelKey,
  selectorValueKey,
  field,
  form,
  selectorOptions,
  innerOnChange,
}) => {
  console.log('selesele',selectorOptions)
  const errorExists = form.errors[field.name];

  const selectedOption = selectorOptions?.find(
    (item) => item[selectorValueKey] === form.values[field.name]
  );
console.log('selectedOptionselectedOptionselectedOptionselectedOption',selectedOption)
  return (
    <div>
      <Select
        value={
          selectedOption
            ? {
                label: selectedOption[selectorLabelKey],
                value: selectedOption[selectorValueKey],
              }
            : null
        }
        isClearable
        options={selectorOptions?.map((item) => {
          return {
            label: item[selectorLabelKey],
            value: item[selectorValueKey],
          };
        })}
        placeholder={placeholder}
        styles={SelectColourStyles(errorExists)}
        onChange={(value) => {
          if (innerOnChange) innerOnChange?.(value?.value);
          form.setFieldValue(field.name, value?.value);
        }}
      />
      <ErrorMessage error={form.errors[field.name]} />
    </div>
  );
};
export default SelectField;

const SelectColourStyles = (errorExists) => {
  return {
    control: (styles, { isFocused }) => ({
      ...styles,
      height: "47px",
      color: errorExists ? "#dc2626" : "#989898", // Change text color here
      backgroundColor: "transparent",
      borderColor: errorExists ? "#dc2626" : "none",
      boxShadow: isFocused ? "none" : "none",
      borderRadius: "0.75rem",
      cursor: "pointer",
      ":hover": {
        ...styles[":hover"],
        border: "1px solid",
        borderColor: "#e9ecef",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: "#000",
        backgroundColor: "#fff",

        cursor: "pointer",
        ":hover": {
          ...styles[":hover"],
          backgroundColor: "#ea494f",
          color: "white",
        },
      };
    },
    placeholder: (styles) => ({
      ...styles,
      color: "#989898", // Change placeholder text color here
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      color: "#989898", // Change selected value text color here
    }),
    input: (styles) => ({
      ...styles,
      color: "#989898", // Change input text color to white here
    }),
  };
};
