import React from 'react';
import { Row, Col, Input } from 'reactstrap';

const SocialLinkCMS = ({ index, value, onChange }) => {
    return (
        <div className="d-flex align-items-center mb-2">
            <Col md={12}>
                <Input
                    type="url"
                    name={`socialLinks[${index}].url`}
                    value={value || ''} 
                    onChange={onChange} 
                    placeholder={`Enter Social Link URL ${index}`}
                />
            </Col>
        </div>
    );
};

export default SocialLinkCMS;
