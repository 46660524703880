import React from "react";
import MainDashboard from "views/admin/default";
import Profile from "views/admin/profile";
import {
  FaBriefcase,
  FaList,
  FaLocationArrow,
  FaQuestionCircle,
  FaUsers,
} from "react-icons/fa";

// Auth Imports
import SignIn from "./views/auth/SignIn";

// Icon Imports
import { MdHome, MdPerson, MdLock, MdCastForEducation, MdHistoryEdu, MdContacts, MdWorkHistory } from "react-icons/md";
import { FaHeartbeat } from "react-icons/fa";
import { MdAddCircle } from "react-icons/md";
import { FaGavel } from "react-icons/fa6";
import FAQs from "views/admin/Faqs/Faqs";
import SignUp from "views/auth/SignUp";
import { HiAcademicCap, HiUsers } from "react-icons/hi";
import { RiUserSharedFill } from "react-icons/ri";
import { FaFileSignature } from "react-icons/fa6";
import { FaUserTie } from "react-icons/fa";
import { TbDeviceIpadMinus } from "react-icons/tb";
// local imports
import AllUsers from "views/admin/Users/allUsers";
import AddUser from "views/admin/Users/addUser";
import Professionals from "views/admin/Users/Professionals";
import Members from "views/admin/Users/Members";
import HealthCategories from "views/admin/health/AllCategories";
import AllHealthProfessionals from "views/admin/health/AllProfessionals";
import AddHealthCategory from "views/admin/health/AddCategory";
import LegalCategories from "views/admin/legal/AllCategories";
import AllLegalProfessionals from "views/admin/legal/AllProfessionals";
import AddLegalCategory from "views/admin/legal/AddCategory";
import NotaryCategories from "views/admin/notary/AllCategories";
import AllNotaryProfessionals from "views/admin/notary/AllProfessionals";
import AddNotaryCategory from "views/admin/notary/AddCategory";
import ArchitectureCategories from "views/admin/architecture/AllCategories";
import AllArchitectureProfessionals from "views/admin/architecture/AllProfessionals";
import AddArchitectureCategory from "views/admin/architecture/AddCategory";
import AddLocation from "views/admin/location/AddLocation";
import AllLocations from "views/admin/location/AllLocations";
import ProtectedRoutes from "components/common/protected-route";
import ViewProfessional from "views/admin/manageProfessioanl/viewProfessional";
import EducationCategories from "views/admin/education/AllCategories";
import AddEducationCategory from "views/admin/education/AddCategory";
import AllEducationCourses from "views/admin/education/AllCourses";
import AddEducationCourse from "views/admin/education/AddEducationCourse";
import AllSubmittedForms from "views/admin/education/AllSubmittedForms";
import EditEducationCourse from "views/admin/education/EditEducationCourse";
import AllContacts from "views/admin/cms/Contacts"
import AddCultureCategory from "views/admin/culture/AddCategory";
import CultureCategories from "views/admin/culture/AllCategories";
import { BsBuildingsFill } from "react-icons/bs";

import AdministrativeSubcategories from "views/admin/administrative/categories/AdministrativeSubcategories";
import AdministrativeCategories from "views/admin/administrative/categories/AllCategories";
import AddAdministrativeCategory from "views/admin/administrative/categories/AddCategory";
import AddAdministrativeTypeForm from "views/admin/administrative/types/AddAdministrativeTypeForm";
import AddSections from "views/admin/administrative/sections/AddSections";
import UpdateSections from "views/admin/administrative/sections/UpdateSections";
import AdministrativeSections from "views/admin/administrative/sections/AdministrativeSections";
import AdministrativeTypes from "views/admin/administrative/types/AdministrativeTypes";
import HomeCMS from "views/admin/cms/home";
import PrivacyPolicyPage from "views/admin/cms/info-pages/PrivacyPolicy";
import TermsAndConditionsPage from "views/admin/cms/info-pages/TermsCondtions";
import AboutUsPage from "views/admin/cms/info-pages/AboutUs";
import JoinUsPage from "views/admin/cms/info-pages/JoinUs";
import ContactUsPage from "views/admin/cms/info-pages/ContactUs";
const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "dashboard",
    icon: <MdHome className="h-6 w-6" />,
    component: (
      <ProtectedRoutes>
        <MainDashboard />
      </ProtectedRoutes>
    ),
  },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "profile",
  //   icon: <MdPerson className="h-6 w-6" />,
  //   component: <Profile />,
  // },
  {
    header: true,
    name: "Users",
    layout: "/admin",
    icon: <HiUsers className="h-6 w-6" />,
    childrens: [
      {
        name: "All Users",
        layout: "/admin",
        path: "all-users",
        icon: <FaUsers className="h-5 w-4" />,
        component: <ProtectedRoutes><AllUsers /></ProtectedRoutes>,
      },
      {
        name: "Add User",
        layout: "/admin",
        path: "add-user",
        icon: <MdAddCircle className="h-5 w-4" />,
        component: (
          <ProtectedRoutes>
            <AddUser />
          </ProtectedRoutes>
        ),
      },
      {
        name: "Members",
        layout: "/admin",
        path: "members",
        icon: <RiUserSharedFill className="h-5 w-4" />,
        component: <ProtectedRoutes><Members /></ProtectedRoutes>,
      },
      {
        name: "Professionals",
        layout: "/admin",
        path: "professionals",
        icon: <FaBriefcase className="h-5 w-4" />,

        component: <ProtectedRoutes><Professionals /></ProtectedRoutes>,
      },
      {
        name: "Professionals",
        layout: "/admin",
        path: "professionals/view/:id",
        visible: false,
        icon: <FaBriefcase className="h-5 w-4" />,
        component: <ProtectedRoutes><ViewProfessional /></ProtectedRoutes>,
      },

    ],
  },
  {
    header: true,
    name: "Location",
    icon: <FaLocationArrow className="h-6 w-6" />,
    layout: "/admin",
    childrens: [
      {
        name: "Add Location",
        layout: "/admin",
        path: "add-location",
        icon: <MdAddCircle className="h-4 w-4" />,
        component: <ProtectedRoutes><AddLocation /></ProtectedRoutes>,
      },
      {
        name: "All Locations",
        layout: "/admin",
        path: "location-list",
        icon: <FaList className="h-4 w-4" />,
        component: <ProtectedRoutes> <AllLocations /></ProtectedRoutes>,
      },
    ],
  },
  {
    header: true,
    name: "Health",
    icon: <FaHeartbeat className="h-6 w-6" />,
    layout: "/admin",
    childrens: [
      {
        name: "All Categories",
        layout: "/admin",
        path: "all-health-categories",
        icon: <FaList className="h-4 w-4" />,
        component: <ProtectedRoutes> <HealthCategories /></ProtectedRoutes>,
      },
      {
        name: "All Services",
        layout: "/admin",
        path: "all-health-services",
        icon: <FaBriefcase className="h-4 w-4" />,
        component: <ProtectedRoutes> <AllHealthProfessionals /></ProtectedRoutes>,
      },
      {
        name: "Add Category",
        layout: "/admin",
        path: "add-health-category",
        icon: <MdAddCircle className="h-4 w-4" />,
        component: <ProtectedRoutes> <AddHealthCategory /></ProtectedRoutes>,
      },
    ],
  },
  {
    header: true,
    name: "Legal",
    icon: <FaGavel className="h-5 w-6" />,
    layout: "/admin",
    childrens: [
      {
        name: "All Categories",
        layout: "/admin",
        path: "all-legal-categories",
        icon: <FaList className="h-4 w-6" />,
        component: <ProtectedRoutes> <LegalCategories /></ProtectedRoutes>,
      },
      {
        name: "All Services",
        layout: "/admin",
        path: "all-legal-services",
        icon: <FaBriefcase className="h-4 w-6" />,
        component: <ProtectedRoutes> <AllLegalProfessionals /></ProtectedRoutes>,
      },
      {
        name: "Add Categories",
        layout: "/admin",
        path: "add-legal-category",
        icon: <MdAddCircle className="h-4 w-6" />,
        component: <ProtectedRoutes> <AddLegalCategory /></ProtectedRoutes>,
      },
    ],
  },

  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock />,
    component: <SignIn className="h-6 w-6" />,
  },
  {
    name: "Sign Up",
    layout: "/auth",
    path: "sign-up",
    icon: <MdLock />,
    component: <SignUp className="h-6 w-6" />,
  },
  {
    header: true,
    name: "Notary",
    layout: "/admin",
    icon: <FaFileSignature className="h-5 w-6" />,
    childrens: [
      {
        name: "All Categories",
        layout: "/admin",
        path: "all-notary-categories",
        icon: <FaList className="h-4 w-6" />,
        component: <ProtectedRoutes> <NotaryCategories /></ProtectedRoutes>,
      },
      {
        name: "All Services",
        layout: "/admin",
        path: "all-notary-services",
        icon: <FaBriefcase className="h-4 w-6" />,
        component: <ProtectedRoutes><AllNotaryProfessionals /></ProtectedRoutes>,
      },
      {
        name: "Add Category",
        layout: "/admin",
        path: "add-notary-category",
        icon: <MdAddCircle className="h-4 w-6" />,
        component: <ProtectedRoutes> <AddNotaryCategory /></ProtectedRoutes>,
      },
    ],
  },
  {
    header: true,
    name: "Architecture",
    layout: "/admin",
    icon: <BsBuildingsFill className="h-5 w-6" />,
    childrens: [
      {
        name: "All Categories",
        layout: "/admin",
        path: "all-architecture-categories",
        icon: <FaList className="h-4 w-6" />,
        component: <ProtectedRoutes> <ArchitectureCategories /></ProtectedRoutes>,
      },
      {
        name: "All Services",
        layout: "/admin",
        path: "all-architecture-services",
        icon: <FaBriefcase className="h-4 w-6" />,
        component: <ProtectedRoutes><AllArchitectureProfessionals /></ProtectedRoutes>,
      },
      {
        name: "Add Category",
        layout: "/admin",
        path: "add-architecture-category",
        icon: <MdAddCircle className="h-4 w-6" />,
        component: <ProtectedRoutes> <AddArchitectureCategory /></ProtectedRoutes>,
      },
    ],
  },
  {
    header: true,
    name: "Administrative",
    layout: "/admin",
    icon: <FaUserTie className="h-5 w-6" />,
    childrens: [
      {
        name: "All Categories",
        layout: "/admin",
        path: "all-administrative-categories",
        icon: <FaList className="h-4 w-6" />,
        component: <AdministrativeCategories />,
      },

      {
        name: "Add Category",
        layout: "/admin",
        visible: false,

        path: "add-administrative-category",
        icon: <MdAddCircle className="h-4 w-6" />,
        component: <AddAdministrativeCategory />,
      },
      {
        name: "Manage Administrative Types",
        layout: "/admin",
        visible: false,
        path: "add-administrative-type/:parentId/:id?",
        icon: <MdAddCircle className="h-4 w-6" />,
        component: <AddAdministrativeTypeForm />,
      },
      {
        name: "Add Administrative Sections",
        layout: "/admin",
        visible: false,
        path: "add-section/:typeId",
        icon: <MdAddCircle className="h-4 w-6" />,
        component: <AddSections />,
      },
      {
        name: "Update Administrative Sections",
        layout: "/admin",
        visible: false,
        path: "update-sections/:sectionId",
        icon: <MdAddCircle className="h-4 w-6" />,
        component: <UpdateSections />,
      },
      {
        name: "All Administrative Sections",
        layout: "/admin",
        visible: false,
        path: "sections/:typeId",
        icon: <MdAddCircle className="h-4 w-6" />,
        component: <AdministrativeSections />,
      },
      {
        name: "Administrative Types",
        layout: "/admin",
        visible: false,
        path: "administrative-types/:parentId",
        icon: <MdAddCircle className="h-4 w-6" />,
        component: <AdministrativeTypes />,
      },
      {
        name: "Add SubCategory",
        layout: "/admin",
        visible: false,

        path: "add-administrative-category/:parentId",
        icon: <MdAddCircle className="h-4 w-6" />,
        component: <AddAdministrativeCategory />,
      },
      {
        name: "All SubCategory",
        layout: "/admin",
        visible: false,

        path: "administrative-category/:parentId",
        icon: <MdAddCircle className="h-4 w-6" />,
        component: <AdministrativeSubcategories />,
      },

    ],
  },
  {
    header: true,
    name: "Education",
    layout: "/admin",
    icon: <HiAcademicCap className="h-5 w-6" />,
    childrens: [
      {
        name: "All Categories",
        layout: "/admin",
        path: "all-education-categories",
        icon: <FaList className="h-4 w-6" />,
        component: <ProtectedRoutes> <EducationCategories /></ProtectedRoutes>,
      },
      {
        name: "All Courses",
        layout: "/admin",
        path: "all-education-courses",
        icon: <FaBriefcase className="h-4 w-6" />,
        component: <ProtectedRoutes><AllEducationCourses /></ProtectedRoutes>,
      },
      {
        name: "Submitted Forms",
        layout: "/admin",
        path: "submitted-forms",
        icon: <FaBriefcase className="h-4 w-6" />,
        component: <ProtectedRoutes><AllSubmittedForms /></ProtectedRoutes>,
      },
      {
        name: "Add Courses",
        layout: "/admin",
        visible: false,
        path: "add-education-course",
        icon: <FaBriefcase className="h-4 w-6" />,
        component: <ProtectedRoutes><AddEducationCourse /></ProtectedRoutes>,
      },
      {
        name: "Edit Course",
        layout: "/admin",
        visible: false,
        path: "edit-education-course/:id",
        icon: <FaBriefcase className="h-4 w-6" />,
        component: <ProtectedRoutes><EditEducationCourse /></ProtectedRoutes>,
      },
      {
        name: "Add Category",
        layout: "/admin",
        visible: false,
        path: "add-education-category",
        icon: <MdAddCircle className="h-4 w-6" />,
        component: <ProtectedRoutes> <AddEducationCategory /></ProtectedRoutes>,
      },
    ],
  },
  {
    header: true,
    name: "Culture & History",
    layout: "/admin",
    icon: <MdWorkHistory className="h-5 w-6" />,
    childrens: [
      {
        name: "All Categories",
        layout: "/admin",
        path: "all-culture-categories",
        icon: <FaList className="h-4 w-6" />,
        component: <ProtectedRoutes> <CultureCategories /></ProtectedRoutes>,
      },


      {
        name: "Add Category",
        layout: "/admin",
        visible: false,
        path: "add-culture-category",
        icon: <MdAddCircle className="h-4 w-6" />,
        component: <ProtectedRoutes> <AddCultureCategory /></ProtectedRoutes>,
      },
    ],
  },
  {
    name: "ContactsUs",
    layout: "/admin",
    icon: <MdContacts className="h-5 w-6" />,
    path: "all-contact", // Moved path up to the main object
    // icon: <FaFileSignature className="h-5 w-6" />,
    component: <ProtectedRoutes><AllContacts /></ProtectedRoutes>, // Directly include the component
  },
  {
    header: true,
    name: "CMS",
    layout: "/admin",
    icon: <HiUsers className="h-6 w-6" />,
    childrens: [
      {
        name: "Home",
        layout: "/admin",
        path: "cms/home",
        icon: <FaUsers className="h-5 w-4" />,
        component: <ProtectedRoutes><HomeCMS /></ProtectedRoutes>,
      },
      {
        name: "Privacy Policy",
        layout: "/admin",
        path: "cms/privacy-policy",
        icon: <FaList className="h-5 w-4" />,
        component: <ProtectedRoutes><PrivacyPolicyPage /></ProtectedRoutes>,
      },
      {
        name: "Terms and Conditions",
        layout: "/admin",
        path: "cms/terms-and-conditions",
        icon: <FaList className="h-5 w-4" />,
        component: <ProtectedRoutes><TermsAndConditionsPage /></ProtectedRoutes>, 
      },
      {
        name: "About Us",
        layout: "/admin",
        path: "cms/about-us",
        icon: <FaList className="h-5 w-4" />,
        component: <ProtectedRoutes><AboutUsPage /></ProtectedRoutes>,  
      },
      {
        name: "Contact Us",
        layout: "/admin",
        path: "cms/contact-us",
        icon: <FaList className="h-5 w-4" />,
        component: <ProtectedRoutes><ContactUsPage /></ProtectedRoutes>,
      },
      {
        name: "Join Us",
        layout: "/admin",
        path: "cms/join-us",
        icon: <FaList className="h-5 w-4" />,
        component: <ProtectedRoutes><JoinUsPage /></ProtectedRoutes>,  
      },
    ],
  },
  // {
  //   name: "FAQs",
  //   layout: "/admin",
  //   path: "faqs",
  //   icon: <FaQuestionCircle className="h-5 w-6" />,
  //   component:<ProtectedRoutes> <FAQs /></ProtectedRoutes>,
  // },
];
export default routes;
