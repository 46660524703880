import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { useCreateDomainSectionMutation, useUpdateDomainSectionMutation } from 'redux/queries/cms/homeCms';
import { successToast, errorToast } from 'utils';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import DomainCard from 'components/cms/domains-card';

const DomainsSection = ({ setFieldValue, values, pageId, domainData }) => {
  const [createDomainSection] = useCreateDomainSectionMutation();
  const [updateDomainSection] = useUpdateDomainSectionMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const domainNames = [
    'Health', 'Legal', 'Notary', 'Architecture', 'Administrative', 'Education', 'Culture',
  ];

  useEffect(() => {
    if (domainData && domainData.sectionDetails && domainData.sectionDetails.domains) {
      const prefilledDomains = domainNames.map((domainName, index) => {
        const domain = domainData.sectionDetails.domains[index];
        return {
          domainName: domain.name || domainName,
          image: domain.image || null,
        };
      });
      setFieldValue('domains', prefilledDomains);
    }
    if (!values.domains) {
      setFieldValue('domains', Array(domainNames.length).fill({ domainName: '', image: null }));
    }
  }, [domainData, setFieldValue]);

  const handleSubmit = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append('pageId', pageId);
    formData.append('sectionName', 'domains');
    formData.append('pageName', 'home');

    values.domains.forEach((domain, index) => {
      const domainName = domainNames[index].toLowerCase().replace(/ /g, '');
      formData.append(`${domainName}Image`, domain.image);
    });

    try {
      if (domainData?._id) {
        await updateDomainSection({ body: formData, sectionId: domainData._id }).unwrap();
        successToast('Domain Section updated successfully!');
      } else {
        await createDomainSection(formData).unwrap();
        successToast('Domain Section created successfully!');
      }
    } catch (error) {
      errorToast('Error handling Domain Section!');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleImageChange = (e, index) => {
    const updatedDomains = [...values.domains];
    updatedDomains[index] = { ...updatedDomains[index], image: e.target.files[0] };
    setFieldValue('domains', updatedDomains);
  };

  return (
    <div className="section mb-2 p-2 rounded-lg shadow-md">
      <div className="swiper-container bg-gray-100 rounded-lg p-2 relative">
        <Swiper
          modules={[Navigation]}
          spaceBetween={30}
          slidesPerView={3}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
            enabled: true,
          }}
          breakpoints={{
            1024: { slidesPerView: 3 },
            768: { slidesPerView: 2 },
            480: { slidesPerView: 1 },
          }}
          style={{
            '--swiper-navigation-size': '20px',
            '--swiper-navigation-sides-offset': '-15px',
          }}
        >
          {domainNames.map((domainName, index) => (
            <SwiperSlide key={index}>
              <DomainCard
                domain={values.domains[index]?.domainName || domainName}
                image={values.domains[index]?.image}
                handleImageChange={(e) => handleImageChange(e, index)}
                imagePreview={values.domains[index]?.image}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="swiper-button-next"></div>
        <div className="swiper-button-prev"></div>
      </div>

      <div className="flex justify-end mt-6 ">
        <Button
          onClick={handleSubmit}
          className="w-1/2 sm:w-1/3 md:w-1/4 btn-primary text-white font-bold  rounded-lg transition-all duration-200"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : domainData?._id ? 'Update Domain Section' : 'Submit Domain Section'}
        </Button>
      </div>
    </div>
  );
};

export default DomainsSection;
