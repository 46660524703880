import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { Button, Row, Col, Input, FormGroup, Label } from 'reactstrap';
import SocialLinkCMS from 'components/cms/cms-social-links';
import { successToast, errorToast } from 'utils';
import { useGetPageQuery, useCreatePageMutation } from 'redux/queries';
import { useGetContentSectionQuery, useCreateContentSectionMutation, useUpdateContentSectionMutation } from 'redux/queries/cms/info-pages.js/contentCMS';
import CreatePage from 'components/cms/create-page';

const ContactUsPage = ({ pageName = 'contact-us' }) => {
    const initialValues = {
        heading: '',
        description: '',
        email: '',
        address: '',
        socialLinks: [{ url: '' }, { url: '' }]
    };

    const [formData, setFormData] = useState(initialValues);

    const { data: pageData, isLoading: pageIsLoading } = useGetPageQuery(pageName);
    const { data: sectionData, isLoading } = useGetContentSectionQuery({
        pageId: pageData?._id,
        sectionName: 'contact-us'
    });

    useEffect(() => {
        if (sectionData?.data) {
            setFormData({
                heading: sectionData.data.sectionDetails.sectionDetail.heading || '',
                description: sectionData.data.sectionDetails.sectionDetail.description || '',
                email: sectionData.data.sectionDetails.sectionDetail.email || '',
                address: sectionData.data.sectionDetails.sectionDetail.address || '',
                socialLinks: sectionData.data.sectionDetails.sectionDetail.socialLinks || [{ url: '' }, { url: '' }]
            });
        }
    }, [sectionData]);

    const [createContentSection] = useCreateContentSectionMutation();
    const [updateContentSection] = useUpdateContentSectionMutation();

    const handleFormSubmit = async (values) => {
        const sectionPayload = {
            pageId: pageData?._id,
            pageName: pageData?.pageName,
            sectionName: 'contact-us',
            sectionTitle: values.heading,
            sectionDetail: {
                heading: values.heading,
                description: values.description,
                email: values.email,
                address: values.address,
                socialLinks: values.socialLinks
            }
        };

        try {
            const response = sectionData?.data
                ? await updateContentSection({ body: sectionPayload, sectionId: sectionData.data._id }).unwrap()
                : await createContentSection(sectionPayload).unwrap();

            if (response.success) {
                successToast(sectionData?.data ? 'Section updated successfully!' : 'Section created successfully!');
            } else {
                errorToast('Failed to save section.');
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            errorToast('An error occurred. Please try again.');
        }
    };

    const [createPage] = useCreatePageMutation();

    const handleCreatePage = async () => {
        if (!pageData) {
            try {
                const response = await createPage({ pageName }).unwrap();
                response.success ? successToast('Page created successfully!') : errorToast('Failed to create page.');
            } catch (error) {
                console.error("Error creating page:", error);
                errorToast('An error occurred. Please try again.');
            }
        }
    };

    if (pageIsLoading || isLoading) {
        return <div className="flex justify-center items-center p-8"><span className="text-xl text-gray-600">Loading...</span></div>;
    }

    return (
        <div className="container mx-auto p-6">
            {!pageData ? (
                <CreatePage pageName={pageName} onCreatePage={handleCreatePage} />
            ) : (
                <Formik initialValues={formData} enableReinitialize onSubmit={handleFormSubmit}>
                    {({ values, handleChange }) => (
                        <Form>
                            <Row className="mb-4">
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="heading">Heading</Label>
                                        <Input type="text" name="heading" id="heading" value={values.heading} onChange={handleChange} placeholder="Enter Heading" />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="email">Email</Label>
                                        <Input type="email" name="email" id="email" value={values.email} onChange={handleChange} placeholder="Enter Email" />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row className="mb-4">
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="address">Address</Label>
                                        <Input type="text" name="address" id="address" value={values.address} onChange={handleChange} placeholder="Enter Address" />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row className="mb-4">
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="description">Description</Label>
                                        <Input type="textarea" name="description" id="description" value={values.description} onChange={handleChange} placeholder="Enter Description" />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row className="mb-4">
                                <Col md={6}>
                                    <SocialLinkCMS index={1} value={values.socialLinks[0]?.url || ''} onChange={(e) => {
                                        const updatedLinks = [...values.socialLinks];
                                        updatedLinks[0].url = e.target.value;
                                        handleChange({ target: { name: 'socialLinks', value: updatedLinks } });
                                    }} />
                                </Col>
                                <Col md={6}>
                                    <SocialLinkCMS index={2} value={values.socialLinks[1]?.url || ''} onChange={(e) => {
                                        const updatedLinks = [...values.socialLinks];
                                        updatedLinks[1].url = e.target.value;
                                        handleChange({ target: { name: 'socialLinks', value: updatedLinks } });
                                    }} />
                                </Col>
                            </Row>

                            <Row className="d-flex justify-content-end">
                                <Col md="auto">
                                    <Button type="submit" className="btn-sm btn-primary mt-4">
                                        {sectionData ? 'Update Section' : 'Create Section'}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};

export default ContactUsPage;
