import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";

export function SidebarLinks(props) {
  let location = useLocation();

  const { routes } = props;
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const [openStates, setOpenStates] = useState({});
  const toggleDropdown = (key) => {
    setOpenStates((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const createLinks = (routes, parentKey = "", parentRoute) => {
    return routes.map((route, index) => {
      if (
        (route.layout === "/admin" && route?.visible != false) ||
        // route.layout === "/auth" ||
        route.layout === "/rtl"
      ) {
        const key = parentKey ? `${parentKey}-${index}` : `${index}`;
        const isOpen = openStates[key] || false;
        return (
          <React.Fragment key={key}>
            <Link
              to={
                route.header
                  ? "#"
                  : parentRoute
                    ? parentRoute + "/" + route.path
                    : route.layout + "/" + route.path
              }
              >

              {console.log("route.name == 'CMS'",route.name == 'CMS')}
              <div
                onClick={() => toggleDropdown(key)}
                className={`${route.name == 'CMS' && 'mt-[40px] border-t mx-1 border-gray-200'} relative mb-3 flex justify-between pe-3 hover:cursor-pointer`}
              >
                <li className="my-[3px] flex cursor-pointer items-center px-8">
                  <span
                    className={`${activeRoute(route.path)
                        ? "font-bold text-brand-500 dark:text-white"
                        : "font-medium text-gray-600"
                      }`}
                  >
                    {route.icon ? route.icon : <DashIcon />}{" "}
                  </span>
                  <p
                    className={`leading-1 ml-4 flex ${activeRoute(route.path)
                        ? "font-bold text-navy-700 dark:text-white"
                        : "font-medium text-gray-600"
                      }`}
                  >
                    {route.name}
                  </p>
                </li>
                {route?.childrens?.length > 0 &&
                  (isOpen ? (
                    <IoIosArrowDown className="mt-2" />
                  ) : (
                    <IoIosArrowForward className="mt-2" />
                  ))}
                {activeRoute(route.path) ? (
                  <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
                ) : null}
              </div>
            </Link>

            {isOpen && route?.childrens && route?.childrens?.length > 0 && (
              <div className="ms-3">
                {createLinks(route?.childrens, key, route.path)}
              </div>
            )}
          </React.Fragment>
        );
      }
      return null;
    });
  };

  // BRAND
  return createLinks(routes);
}

export default SidebarLinks;
