import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import InputField from "components/fields/InputField";

const SectionInput = ({
    sectionTitle,
    headingValue,
    descriptionValue,
    imagePreview,
    onHeadingChange,
    onDescriptionChange,
    onImageChange,
}) => (
    <Card className="mb-6 shadow-lg rounded-lg p-6 bg-white">
        <CardBody>
            <h2 className="text-3xl font-semibold mb-6 text-gray-800">{sectionTitle} Section</h2>

            <Row className="mb-6">
                <Col md={6}>
                    <InputField
                        id={`${sectionTitle.toLowerCase()}Heading`}
                        label={`${sectionTitle} Heading`}
                        placeholder={`Enter ${sectionTitle} Heading`}
                        value={headingValue || ""}
                        onChange={(e) => onHeadingChange(e.target.value)}
                    />
                </Col>
                <Col md={6}>
                    <InputField
                        id={`${sectionTitle.toLowerCase()}Description`}
                        label={`${sectionTitle} Description`}
                        placeholder={`Enter ${sectionTitle} Description`}
                        value={descriptionValue || ""}
                        onChange={(e) => onDescriptionChange(e.target.value)}
                        type="textarea"
                    />
                </Col>
            </Row>

            <Row className="mt-6">
                <Col md={6}>
                    <label
                        htmlFor={`${sectionTitle.toLowerCase()}Image`}
                        className="block text-gray-700 text-sm font-bold mb-2"
                    >
                        {sectionTitle} Image
                    </label>
                    <input
                        type="file"
                        id={`${sectionTitle.toLowerCase()}Image`}
                        accept="image/*"
                        onChange={(e) => onImageChange(e.target.files[0])}
                        className="border border-gray-300 rounded-md p-2 w-full"
                    />
                </Col>
                <Col md={6} className="flex justify-center items-center">
                    {imagePreview && (
                        <img
                            src={imagePreview}
                            alt={`${sectionTitle} Image Preview`}
                            className="w-48 h-48 object-cover rounded-lg shadow-md border-2 border-gray-200"
                        />
                    )}
                </Col>
            </Row>
        </CardBody>
    </Card>
);

export default SectionInput;
