import React, { useState, useEffect } from 'react';
import { useGetPageQuery } from 'redux/queries';
import {
    useGetAllSectionsForPageQuery,
    useUpdateAboutUsSectionsMutation,
    useCreateAboutUsSectionsMutation,
} from 'redux/queries/cms/info-pages.js/contentCMS';
import { successToast, errorToast } from 'utils';
import SectionInput from 'components/cms/cms-info-section';
import CreatePage from 'components/cms/create-page';
import { useCreatePageMutation } from 'redux/queries';

const AboutUsPage = () => {
    const [sections, setSections] = useState({
        Main: { heading: '', description: '', image: null, imagePreview: null },
        Mission: { heading: '', description: '', image: null, imagePreview: null },
        Vision: { heading: '', description: '', image: null, imagePreview: null },
        Values: { heading: '', description: '', image: null, imagePreview: null },
    });

    const [updateAboutUsSections, { isLoading: isUpdating }] = useUpdateAboutUsSectionsMutation();
    const [createAboutUsSections, { isLoading: isCreating }] = useCreateAboutUsSectionsMutation();
    const [createPage] = useCreatePageMutation();

    const { data: pageData, isLoading: pageLoading } = useGetPageQuery("about-us");
    const { data: allSectionsData, isLoading: sectionsLoading } = useGetAllSectionsForPageQuery({ pageName: pageData?.pageName });

    useEffect(() => {
        if (allSectionsData) {
            const updatedSections = { ...sections };
            allSectionsData.data.forEach(({ sectionName, sectionDetails }) => {
                updatedSections[sectionName] = {
                    heading: sectionDetails.sectionTitle,
                    description: sectionDetails.sectionDetail,
                    image: sectionDetails.sectionImage,
                    imagePreview: sectionDetails.sectionImage,
                };
            });
            setSections(updatedSections);
        }
    }, [allSectionsData]);

    const handleSectionChange = (sectionName, field, value) => {
        setSections((prev) => ({
            ...prev,
            [sectionName]: {
                ...prev[sectionName],
                [field]: value,
            },
        }));
    };

    const handleSubmit = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('pageId', pageData?._id);
        formDataToSend.append('pageName', pageData?.pageName);

        Object.keys(sections).forEach((sectionName) => {
            const section = sections[sectionName];
            const sectionDetails = {
                sectionTitle: section.heading,
                sectionDetail: section.description,
                sectionImage: section.image,
            };

            formDataToSend.append('sections[]', JSON.stringify({ sectionName, sectionDetails }));
            if (section.image) {
                formDataToSend.append(`${sectionName}Image`, section.image);
            }
        });

        try {
            if (allSectionsData) {
                await updateAboutUsSections(formDataToSend).unwrap();
                successToast('About Us sections updated successfully!');
            } else {
                await createAboutUsSections(formDataToSend).unwrap();
                successToast('About Us sections created successfully!');
            }
        } catch (error) {
            errorToast('Error during form submission');
        }
    };

    const handleCreatePage = async () => {
        try {
            const response = await createPage({ pageName: "about-us" }).unwrap();
            if (response.success) {
                successToast('Page created successfully!');
            } else {
                errorToast('Failed to create page.');
            }
        } catch (error) {
            errorToast('Error creating page.');
        }
    };

    if (pageLoading || sectionsLoading) {
        return (
            <div className="flex justify-center items-center p-8">
                <span className="text-xl text-gray-600">Loading...</span>
            </div>
        );
    }

    return (
        <div className="container mx-auto p-6">
            {!pageData ? (
                <CreatePage pageName="about-us" onCreatePage={handleCreatePage} />
            ) : (
                <form>
                    {Object.keys(sections).map((sectionName) => (
                        <SectionInput
                            key={sectionName}
                            sectionTitle={sectionName}
                            headingValue={sections[sectionName].heading}
                            descriptionValue={sections[sectionName].description}
                            imagePreview={sections[sectionName].imagePreview}
                            onHeadingChange={(value) => handleSectionChange(sectionName, 'heading', value)}
                            onDescriptionChange={(value) => handleSectionChange(sectionName, 'description', value)}
                            onImageChange={(file) => {
                                const reader = new FileReader();
                                reader.onloadend = () => {
                                    handleSectionChange(sectionName, 'imagePreview', reader.result);
                                };
                                reader.readAsDataURL(file);
                                handleSectionChange(sectionName, 'image', file);
                            }}
                        />
                    ))}
                    <div className="mt-4">
                        <button
                            className="btn-primary w-full sm:w-1/3"
                            type="button"
                            onClick={handleSubmit}
                            disabled={isUpdating || isCreating}
                        >
                            {(isUpdating || isCreating) ? 'Submitting...' : 'Submit About Us Page'}
                        </button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default AboutUsPage;
