import { useEffect, useState } from 'react';
import { TextEditor } from 'components/common/text-editor';
import InputField from 'components/fields/InputField';
import { successToast, errorToast } from 'utils';
import { useGetPageQuery, useCreatePageMutation } from 'redux/queries';
import {
    useGetContentSectionQuery,
    useCreateContentSectionMutation,
    useUpdateContentSectionMutation,
} from 'redux/queries/cms/info-pages.js/contentCMS';
import CreatePage from 'components/cms/create-page';

const TermsAndConditionsPage = ({ pageName = 'terms-and-conditions', sectionName = 'terms-and-conditions' }) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const { data: pageData, isLoading: pageLoading } = useGetPageQuery(pageName);
    const { data: sectionData, isLoading: sectionLoading } = useGetContentSectionQuery({
        pageId: pageData?._id,
        sectionName,
    });
    const [createPage] = useCreatePageMutation();
    const [createContentSection] = useCreateContentSectionMutation();
    const [updateContentSection] = useUpdateContentSectionMutation();

    const handleCreatePage = async () => {
        if (!pageData) {
            try {
                const response = await createPage({ pageName }).unwrap();
                response.success ? successToast('Page created successfully!') : errorToast('Something went wrong.');
            } catch {
                errorToast('Error creating page.');
            }
        }
    };

    useEffect(() => {
        if (sectionData) {
            setTitle(sectionData?.data?.sectionDetails?.sectionTitle || '');
            setDescription(sectionData?.data?.sectionDetails?.sectionDetail || '');
        }
    }, [sectionData]);

    const onSubmit = async () => {
        const sectionBody = {
            sectionName,
            sectionTitle: title,
            sectionDetail: description,
            pageId: pageData?._id,
            pageName: pageData?.pageName,
        };

        if (sectionData) {
            try {
                const response = await updateContentSection({
                    body: sectionBody,
                    sectionId: sectionData?.data?._id,
                }).unwrap();
                response.success ? successToast('Content section updated successfully!') : errorToast('Failed to update the section.');
            } catch {
                errorToast('Error updating content section.');
            }
        } else {
            try {
                const response = await createContentSection(sectionBody).unwrap();
                response.success ? successToast('Content section created successfully!') : errorToast('Failed to create the section.');
            } catch {
                errorToast('Error creating content section.');
            }
        }
    };

    if (pageLoading || sectionLoading) {
        return (
            <div className="flex justify-center items-center p-8">
                <span className="text-xl text-gray-600">Loading...</span>
            </div>
        );
    }

    return (
        <div className="rounded-sm border border-stroke bg-white px-5 py-6 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5">
            {!pageData ? (
                <CreatePage pageName={pageName} onCreatePage={handleCreatePage} />
            ) : (
                <div className="flex flex-col gap-6">
                    <div>
                        <InputField
                            label="Title"
                            placeholder="Write terms and conditions title here..."
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                    <div>
                        <TextEditor
                            label="Description"
                            placeholder="Write terms and conditions description here..."
                            value={description}
                            className="h-52 mb-20"
                            onChange={setDescription}
                        />
                    </div>
                    <div>
                        <button className="btn-primary" onClick={onSubmit}>
                            Submit
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TermsAndConditionsPage;
