import { injectApiEndpoints } from "redux/api";

export const ContentSectionApi = injectApiEndpoints({
  endpoints: (build) => ({
    createContentSection: build.mutation({
      query: (body) => ({
        url: "/content-section/create",
        method: "post",
        body,
      }),
    }),

    updateContentSection: build.mutation({
      query: ({ body, sectionId }) => ({
        url: `/content-section/update-section/${sectionId}`,
        method: "put",
        body,
      }),
    }),

    deleteContentSection: build.mutation({
      query: ({ sectionId }) => ({
        url: `/content-section/delete-section/${sectionId}`,
        method: "delete",
      }),
    }),

    getContentSection: build.query({
      query: ({ pageId, sectionName }) => ({
        url: `/content-section/get-section/${pageId}/${sectionName}`,
        method: "get",
      }),
    }),
    createAboutUsSections: build.mutation({
      query: (body) => ({
        url: "/content-section/about-us/create-sections",
        method: "post",
        body,
      }),
    }),

    // New endpoint: Get all sections for a page
    getAllSectionsForPage: build.query({
      query: ({ pageName }) => ({
        url: `/content-section/get-all-sections/${pageName}`,
        method: "get",
      }),
    }),
    updateAboutUsSections: build.mutation({
      query: (body) => ({
        url: "/content-section/about-us/update-sections",
        method: "put",
        body,
      }),
    }),

    createJoinUsSections: build.mutation({
      query: (body) => ({
        url: "/content-section/join-us/create-sections",
        method: "post",
        body,
      }),
    }),

    // New: Update Join Us Sections
    updateJoinUsSections: build.mutation({
      query: (body) => ({
        url: "/content-section/join-us/update-sections",
        method: "put",
        body,
      }),
    }),

  }),
});

export const {
  useCreateContentSectionMutation,
  useUpdateContentSectionMutation,
  useDeleteContentSectionMutation,
  useGetContentSectionQuery,
  useCreateAboutUsSectionsMutation,
  useUpdateAboutUsSectionsMutation,
  useGetAllSectionsForPageQuery,
  useCreateJoinUsSectionsMutation,
  useUpdateJoinUsSectionsMutation,
} = ContentSectionApi;
